import { combineReducers } from "redux";
import customer from "./customer";
import isLoading from "./isLoading";
import isItemDetail from "./isItemDetail";
import staffs from "./staffs";
import typeCosts from "./typeCosts";
import carriers from "./carriers";
import dropdown from "./dropdown";
import phieutheodoi from "./phieutheodoi";
import taojoborder from "./taojoborder";
import phieuchitamung from "./phieuchitamung";
import yeucauthanhtoan from "./yeucauthanhtoan";
import duyetjoborder from "./duyetjoborder";
import boatfee from "./boatfee";
import phieuthu from "./phieuthu";
import users from "./users";

const allReducer = combineReducers({
  isLoading,
  customer,
  isItemDetail,
  staffs,
  typeCosts,
  carriers,
  phieutheodoi,
  dropdown,
  taojoborder,
  yeucauthanhtoan,
  phieuchitamung,
  duyetjoborder,
  boatfee,
  phieuthu,
  users
});

export default allReducer;
