import React from "react";
import { Layout } from "antd";
import "../App.css";
import "antd/dist/antd.css";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { RouterWithSubRoutes } from "../RouterWithSubRoutes";
import NavBarComponent from "../Component/NavBarComponent";

const {  Content, Footer } = Layout;

function LayoutCustom({ routes }) {
   return (
    <Router>
      <Layout style={{ minHeight: "100vh" }} >
        {/* <SiderComponent routes={routes}/> */}

        <Layout className="site-layout" >
        <NavBarComponent routes={routes}/>

          <Content style={{ margin: "0 16px" }}>
            <Switch>
              {routes.map((route, i) => {
                return(
                <RouterWithSubRoutes key={i} {...route} />
              )})}
            </Switch>
          </Content>
          <Footer style={{ textAlign: "center" }}>Created by Kai IHT</Footer>
        </Layout>
      </Layout>
    </Router>
  );
}

export default LayoutCustom;
