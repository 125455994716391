import {
  DELETE_YEU_CAU_THANH_TOAN,
  FETCH_YEU_CAU_THANH_TOAN,
} from "./actionTypes";

export const fetchYeuCauThanhToan = (yeucau) => {
  return {
    type: FETCH_YEU_CAU_THANH_TOAN,
    yeucau,
  };
};

export const deleteYeuCauThanhToan = (JOB_NO) => {
  return {
    type: DELETE_YEU_CAU_THANH_TOAN,
    JOB_NO,
  };
};
