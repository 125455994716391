import {
  FETCH_PHIEU_CHI_TAM_UNG,
  DELETE_PHIEU_CHI_TAM_UNG,
} from "../actions/actionTypes";

var initialState = [];

const findIndex = (data, LENDER_NO) => {
  var result = -1;
  data.forEach((customer, index) => {
    if (customer.LENDER_NO === LENDER_NO) {
      result = index;
    }
  });
  return result;
};

const phieuchitamung = (state = initialState, action) => {
  var index = -1;
  switch (action.type) {
    case FETCH_PHIEU_CHI_TAM_UNG:
      state = action.phieuchi;
      return [...state];
    case DELETE_PHIEU_CHI_TAM_UNG:
      index = findIndex(state, action.JOB_NO);
      state.splice(index, 1);
      return [...state];
    default:
      return state;
  }
};

export default phieuchitamung;
