import {
  CLEAR_DETAIL,
  DELETE_CUSTOMER,
  DETAIL_CUSTOMER,
  FETCH_CUSTOMER,
} from "./actionTypes";

export const fetchCustomer = (customer) => {
  return {
    type: FETCH_CUSTOMER,
    customer,
  };
};

export const deleteCustomer = (CUST_NO) => {
  return {
    type: DELETE_CUSTOMER,
    CUST_NO,
  };
};

export const detailCustomer = (customer) => {
  return {
    type: DETAIL_CUSTOMER,
    customer,
  };
};

export const clearCustomer = () => {
  return {
    type: CLEAR_DETAIL,
  };
};
