import { FetchURL } from "../util/help";
import {
  DROP_DOWN_AGENT,
  DROP_DOWN_CARRIER,
  DROP_DOWN_CUSTOMER,
  DROP_DOWN_INFORMATION_USER,
  DROP_DOWN_JOB_ORDER,
  DROP_DOWN_LIST_JOB_HAS_D,
  DROP_DOWN_LIST_JOB_PHIEU_CHI,
  DROP_DOWN_LIST_NOT_CREATED_ORDER,
  DROP_DOWN_LIST_PAYMENT_NOT_CREATE,
  DROP_DOWN_LIST_PHIEU_CHI,
  DROP_DOWN_PAYMENT_DEBIT,
  DROP_DOWN_STAFF,
  DROP_DOWN_TYPE_COSTS,
  LIST_OF_CUSTOMER,
} from "./actionTypes";

//customer
export const dropdownCustomer = (customer) => {
  return {
    type: DROP_DOWN_CUSTOMER,
    customer,
  };
};

export const actDropdownCustomer = () => {
  return (dispatch) => {
    return fetch(FetchURL + "data-basic/customer/type=1")
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          dispatch(dropdownCustomer(data.data));
        }
      })
      .catch((err) => console.log(err));
  };
};

//staff
export const dropdownStaff = (staff) => {
  return {
    type: DROP_DOWN_STAFF,
    staff,
  };
};

export const actDropdownStaff = () => {
  return (dispatch) => {
    return fetch(FetchURL + "data-basic/staff-customs")
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          dispatch(dropdownStaff(data.data));
        }
      })
      .catch((err) => console.log(err));
  };
};

//list not-created-order
export const dropdownListJobNotCreatedOrder = (jobNotCreatedOrder) => {
  return {
    type: DROP_DOWN_LIST_NOT_CREATED_ORDER,
    jobNotCreatedOrder,
  };
};

export const actListJobNotCreatedOrder = () => {
  return (dispatch) => {
    return fetch(FetchURL + "file/job-start/not-created")
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          dispatch(dropdownListJobNotCreatedOrder(data.data));
        }
      })
      .catch((err) => console.log(err));
  };
};

//Job Phieu Chi Tam Ung
export const dropdownJobPhieuChiTamUng = (job) => {
  return {
    type: DROP_DOWN_LIST_JOB_PHIEU_CHI,
    job,
  };
};

export const actJobPhieuChiTamUng = () => {
  return (dispatch) => {
    // return fetch(FetchURL + "file/job-start")
    return fetch(FetchURL + "file/job-start/take=50000")
      .then((res) =>res.json())
      .then((data) => {
        if (data.success === true) {
          dispatch(dropdownJobPhieuChiTamUng(data.data));
        }
      })
      .catch((err) => console.log(err));
  };
};

//list payment not create
export const dropdownListPaymentNotCreate = (job) => {
  return {
    type: DROP_DOWN_LIST_PAYMENT_NOT_CREATE,
    job,
  };
};

export const actDropdownListPaymentNotCreate = () => {
  return (dispatch) => {
    return fetch(FetchURL + "payment/debit-note/not-created")
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          dispatch(dropdownListPaymentNotCreate(data.data));
        }
      })
      .catch((err) => console.log(err));
  };
};

//carries
export const dropdownCarrier = (carrie) => {
  return {
    type: DROP_DOWN_CARRIER,
    carrie,
  };
};

export const actDropdownCarrier = () => {
  return (dispatch) => {
    return fetch(FetchURL + "data-basic/customer/type=2")
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          dispatch(dropdownCarrier(data.data));
        }
      })
      .catch((err) => console.log(err));
  };
};

//agent
export const dropdownAgent = (agent) => {
  return {
    type: DROP_DOWN_AGENT,
    agent,
  };
};

export const actDropdownAgent = () => {
  return (dispatch) => {
    return fetch(FetchURL + "data-basic/customer/type=3")
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          dispatch(dropdownAgent(data.data));
        }
      })
      .catch((err) => console.log(err));
  };
};

//Type Costs
export const dropdownTypeCosts = (costs) => {
  return {
    type: DROP_DOWN_TYPE_COSTS,
    costs,
  };
};

export const actDropdownTypeCosts = () => {
  return (dispatch) => {
    return fetch(FetchURL + "data-basic/type-cost")
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          dispatch(dropdownTypeCosts(data.data));
        }
      })
      .catch((err) => console.log(err));
  };
};

//dropdown job order
export const dropdownJobOrder = (joborder) => {
  return {
    type: DROP_DOWN_JOB_ORDER,
    joborder,
  };
};

export const actDropdownJobOrder = () => {
  return (dispatch) => {
    return fetch(FetchURL + "file/job-order")
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          dispatch(dropdownJobOrder(data.data));
        }
      })
      .catch((err) => console.log(err));
  };
};

//dropdown list of customer
export const actListJobsOfCustomer = (list) => {
  return {
    type: LIST_OF_CUSTOMER,
    list,
  };
};

export const actListJobsOfCustomerRequest = (CUST_NO) => {
  return (dispatch) => {
    return fetch(FetchURL + `print/file/job-order/custno=${CUST_NO}`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          var newData =data.job_m;
          for (var i = 0; i < newData.length; i++) {
            newData[i].key = i.toString();
          }
          dispatch(actListJobsOfCustomer(newData));
        }
      })
      .catch((err) => console.log(err));
  };
};

//dropdown infomation user
export const dropdownInformationUser = (information) => {
  return {
    type: DROP_DOWN_INFORMATION_USER,
    information,
  };
};

export const actDropdownInformationUser = () => {
  return (dispatch) => {
    return fetch(FetchURL + `system/user`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          dispatch(dropdownInformationUser(data.data));
        }
      })
      .catch((err) => console.log(err));
  };
};

export const dropdownPaymentDebit = (paymentDebit) => {
  return {
    type: DROP_DOWN_PAYMENT_DEBIT,
    paymentDebit,
  };
};

export const actDropdownPaymentDebit = () => {
  return (dispatch) => {
    return fetch(FetchURL + "payment/debit-note")
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          dispatch(dropdownPaymentDebit(data.data));
        }
      })
      .catch((err) => console.log(err));
  };
};

//list phieu chi
export const dropdownListPhieuChi = (data) => {
  return {
    type: DROP_DOWN_LIST_PHIEU_CHI,
    data,
  };
};

export const actDropdownListPhieuChi = () => {
  return (dispatch) => {
    return fetch(FetchURL + "payment/lender")
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          dispatch(dropdownListPhieuChi(data.data));
        }
      })
      .catch((err) => console.log(err));
  };
};

//list-job-has-debit
export const dropdownListJobHasDebit = (data) => {
  return {
    type: DROP_DOWN_LIST_JOB_HAS_D,
    data,
  };
};

export const actDropdownListJobHasDebit = () => {
  return (dispatch) => {
    return fetch(FetchURL + "payment/debit-note/list-job-has-d")
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          dispatch(dropdownListJobHasDebit(data.data));
        }
      })
      .catch((err) => console.log(err));
  };
};

//list-cust-job Phieu Yeu Cau Thanh Toan
export const actDropDownListCustJob = () => {
  return (dispatch) => {
    return fetch(FetchURL + "payment/debit-note/list-cust-job")
      .then((res) => res.json())
      .then((data) => {
        if (data.success === true) {
          dispatch(dropdownCustomer(data.data));
        }
      })
      .catch((err) => console.log(err));
  };
};
