import { DELETE_JOB_START, FETCH_JOB_START } from "../actions/actionTypes";

var initialState = [];

const findIndex = (data, JOB_NO) => {
  var result = -1;
  data.forEach((customer, index) => {
    if (customer.JOB_NO === JOB_NO) {
      result = index;
    }
  });
  return result;
};

const phieutheodoi = (state = initialState, action) => {
  var index = -1;
  switch (action.type) {
    case FETCH_JOB_START:
      state = action.job;
      return [...state];
    case DELETE_JOB_START:
      index = findIndex(state, action.JOB_NO);
      state.splice(index, 1);
      return [...state];
    default:
      return state;
  }
};

export default phieutheodoi;
