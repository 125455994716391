import { DELETE_TYPECOST, FETCH_TYPECOST } from "../actions/actionTypes";

var initialState = [];

const findIndex = (data, DESCRIPTION_CODE) => {
  var result = -1;
  data.forEach((customer, index) => {
    if (customer.DESCRIPTION_CODE === DESCRIPTION_CODE) {
      result = index;
    }
  });
  return result;
};

const typeCosts = (state = initialState, action) => {
  var index = -1;
  switch (action.type) {
    case FETCH_TYPECOST:
      state = action.typeCost;
      return [...state];
    case DELETE_TYPECOST:
      index = findIndex(state, action.DESCRIPTION_CODE);
      state.splice(index, 1);
      return [...state];
    default:
      return state;
  }
};

export default typeCosts;
