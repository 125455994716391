
import { FETCH_JOB_START, DELETE_JOB_START } from "./actionTypes";

export const fetchPhieuTheoDoi = (job) => {
  return {
    type: FETCH_JOB_START,
    job,
  };
};

export const deletePhieuTheoDoi = (JOB_NO) => {
  return {
    type: DELETE_JOB_START,
    JOB_NO,
  };
};
