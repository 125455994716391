import { DELETE_PHIEU_THU, FETCH_PHIEU_THU } from "./actionTypes";

export const fetchPhieuThu = (phieuthu) => {
  return {
    type: FETCH_PHIEU_THU,
    phieuthu,
  };
};

export const deletePhieuThu = (RECEIPT_NO) => {
  return {
    type: DELETE_PHIEU_THU,
    RECEIPT_NO,
  };
};
